import styled from 'styled-components';
import { getGreenFrameStyles } from '../styled';

export const Container = styled.div`
	width: 100%;


	.subtitle{
		text-align: center;
	}

	.camera-wrapper{
		display: none;
	}

	.arrow-container{
		display: flex;
		justify-content: center;
	}

	.arrow-up{
		width: 4rem;
		position: relative;
		top: 2.5rem;
		margin-top: 4em;
	}

	.arrow-animation{
		animation: pulse1 1.5s ease-in-out 3;
	}

	.not-mirrored{
		color: black;
		text-align: center;
		font-size: 16px;
		padding: 5px 45px;
	}


	@keyframes pulse1 {
		0% {
			opacity: 0.3;
			transform: scale(0.5);
		}

		30% {
			opacity: 1;
			transform: scale(1.1);
		}

		60% {
			opacity: 1;
			transform: scale(1.2);
		}

		100% {
			opacity: 0.4;
			transform: scale(1.2);
		}
	}

	.general {
		margin-top: 5%;
		display: flex;
		justify-content: center;
		border-radius: 23px;

		.image-preview {
			width: 220px;
			height: 237px;
			background: #ffffff;
			box-shadow: 0px 0px 207px -18px rgba(255, 255, 255, 0.25);
			border-radius: 23px;
			position: relative;

			img {
				width: 100%;
				height: 100%;
				border-radius: 23px;
				object-fit: cover;
			}

			${getGreenFrameStyles}
		}
	}

	.desktop {
		display: none;
	}

	@media (min-width: 768px) {

		.camera-wrapper{
			display: block;
		}

		.general {
			margin-top: 1rem;

			.image-preview {
				width: 400px;
				height: 390px;
			}

			
		}

		.desktop {
			display: flex;
			flex-direction: column;
			align-items: center;

			.btn {
				 margin-top: -1rem; 
			}

			.image-bottom-words {
				margin-top: 2rem;
				margin-bottom: 2rem;
			}

			.bottom-buttons{
				flex-direction: row;
				display: flex;
				justify-content: space-around;
				margin-top: 40px;
				width: 100%;
			}
		}
	}

	@media (min-width: 1024px) {
		.general {
			margin-top: 2rem;

			.image-preview {
				width: 497px;
				height: 470px;
			}
		}
	}
`;

export const MBContent = styled.div`
	margin-top: 1%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.btn {
		margin-top: 5%;
	}

	.no-bg-btn {
		margin-top: 1.5rem;
	}

	@media (min-width: 768px) {
		display: none;
	}
`;
