import React, { useEffect, useState, useRef } from 'react';
import { navigate } from 'gatsby';

import { Container, MBContent } from './image-preview-content.styled';
import Button from '../button/button.comp';
import PhoneCamera from '../phone-camera/phone-camera.comp';
import { useData } from '../../context/data';
import { useCamera } from '../../utils/hooks/use-camera';
import { useIsMobile } from '../../utils/hooks/use-is-mobile';
import DesktopWebcam from '../desktop-webcam/desktop-webcam.comp';
import i18next from '../i18n';


export default function ImagePreviewContent() {
	const { state, capture, uploadImageForProcessing, retakeImage } = useData();
	const { camRef, takePicture } = useCamera();
	const [timeLeft, setTimeLeft] = useState(null);
	const [isCamera, setIsCamera] = useState(false);
	const [isPictureTaken, _setIsPictureTaken] = useState(false)
	const isPictureTakenRef = useRef(isPictureTaken)
	const [startCountdown, setStartCountdown] = useState(false)
	const isMobile = useIsMobile();

	const setIsPictureTaken = (value) => {
		isPictureTakenRef.current = value;
		_setIsPictureTaken(value)
	}


	// Take picture on spacebar keypress
	useEffect(() => {
		document.addEventListener('keydown', keydownPressed)
	}, [])

	const keydownPressed = (e => {
		if(e.key == ' '){
				
			if(!isPictureTakenRef.current){
				console.log('taking picture')
				setIsPictureTaken(true)
				takePictureStart()

			}
		}
	})
	
	useInterval(() => {

		// detects Safari by duck-typing
		// Safari doesn't allow to play sounds
		var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
		

		if(startCountdown == true){

			if(timeLeft == null){

				let audio = document.getElementById("num3");
				if(!isSafari){
					audio.play()
				}
				setTimeLeft(3)	
			}

			else{
			  if(timeLeft == 0){
				setStartCountdown(false)
			  }
			  else{
				
				let number = timeLeft - 1;
				if(number === 1 || number === 2){
					let audio = document.getElementById("num" + number);
					if(!isSafari){
						audio.play()
					}
				}
				if(number == 0){
					let audio = document.getElementById('photo-sound');
					if(!isSafari){
						audio.play()
					}
				}

			  	setTimeLeft(number);
			  }

	  	  
	    }	 
		}
	 
	  
	}, 1000);

	// From https://overreacted.io/making-setinterval-declarative-with-react-hooks/
	function useInterval(callback, delay) {
	  const savedCallback = useRef();

	  // Remember the latest callback.
	  useEffect(() => {
	    savedCallback.current = callback;
	  }, [callback]);

	  // Set up the interval.
	  useEffect(() => {
	    function tick() {
	      savedCallback.current();
	    }
	    if (delay !== null) {
	      let id = setInterval(tick, delay);
	      return () => clearInterval(id);
	    }
	  }, [delay]);
	}

	function isCamAlreadyActive(){
		navigator.mediaDevices.getUserMedia({video: true}).then(function(stream){
		  var result = stream.getVideoTracks().some(function (track) {
				return track.enabled && track.readyState === 'live';
			});
			if( result ) {
				setIsCamera(true)
			}
		  })
		.catch(function(err) { console.log(err); setIsCamera(false); });
		}

	function takePictureStart(){
		setIsPictureTaken(true)
		document.querySelector('.arrow-up').classList.add("arrow-animation")
		capture(); 
		setStartCountdown(true)
	}

	useEffect(() => {
		if (!isMobile) {
			isCamAlreadyActive()
		}
		
		if (!state.imagePreview && isMobile) {
			camRef && takePicture();
		}

	}, [state.imagePreview, isMobile, takePicture, camRef]);




	return (
		<Container>

			{/* Audios with numbers */}
			<audio id="num1" src="/sounds/trimmed_beep.mp3"></audio>
			<audio id="num2" src="/sounds/trimmed_beep.mp3"></audio>
			<audio id="num3" src="/sounds/trimmed_beep.mp3"></audio>
			<audio id="photo-sound" src="/sounds/photo.wav"></audio>

			<div class='arrow-container'>
				<img class='arrow-up' src={'/images/arrow-up.svg'} />	
			</div>
			

			<h2 className='subtitle'> Look at the Camera </h2>

			<div className='general' >
				{isMobile ? (
					<div className='image-preview'>
						<img src={state.imagePreview} alt='' />
					</div>
				) : (
					<DesktopWebcam takePicture={takePictureStart} />
				)}
			</div>

			<div className='desktop'>
				<div className='image-bottom-words'>{ i18next.t('image_preview.countdown') } </div>
				{state.imagePreview ? (
					<div className='desktop bottom-buttons'>
						<Button
							text= { i18next.t('button.retake_photo') }
							variant='outline'
							size='lg'
							className='btn'
							onClick={ () => {
								window.location.reload();
							}}
						/>
						<Button
							text= { i18next.t('button.next') }
							variant='outline'
							size='lg'
							className='btn'
							onClick={() => {
								uploadImageForProcessing()
								navigate('/processing')
							}}
						/>
					</div>
				) : (
					<Button
						text={timeLeft === null? i18next.t('button.capture') : timeLeft}
						variant='outline'
						size='lg'
						className='btn'
						onClick={() => { takePictureStart() } }
					/>
				)}
			</div>

			<MBContent>
				
				
				{ state.imagePreview ? (
				  <p className="not-mirrored">Check the image is not mirrored. Adjust your camera settings to correct.</p>
				) : 
				  <p>There will be a 5 second countdown before capturing the image.</p>
				}

				
				aksjdlkasjdklasjdkla

				{state.imagePreview ? (
					<>
					<Button
							text= { i18next.t('button.retake_photo') }
							variant='outline'
							size='lg'
							className='btn'
							onClick={ () => {
								window.location.reload();
							}}
						/>
					<Button
						text= { i18next.t('button.next') }
						size='lg'
						className='btn'
						onClick={() => {
							uploadImageForProcessing()
							navigate('/processing')
						} }
					/>
					</>
				) : (
					<>
					
					<Button
						text={timeLeft === null? i18next.t('button.capture') : timeLeft}
						variant='outline'
						size='lg'
						className='btn'
						onClick={() => { takePictureStart() } }
					/>
					</>
				)}
				<PhoneCamera camRef={camRef} />
			</MBContent>
		</Container>
	);
}
